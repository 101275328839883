<template>
  <div class="main-container">
    <!-- 导航栏 -->
    <div class="navigation-bar">
      <el-button class="back-btn" icon="el-icon-back" @click="back"
        >返回</el-button
      >
      <el-breadcrumb style="margin-left: 16px" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item
          ><a href="/#/riskHomeManagement">风险管理</a></el-breadcrumb-item
        >
        <el-breadcrumb-item>案件列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 筛选 -->
    <div class="filter">
      <el-form
        label-width="90px"
        :inline="true"
        :model="searchParams"
        class="demo-form-inline"
        v-if="!isFold"
      >
        <el-form-item label="报案号" style="margin-left: 51px">
          <el-input
            v-model="searchParams.realCaseNo"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="保单号" style="margin-left: 51px">
          <el-input
            v-model="searchParams.policyNo"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="保险公司" style="margin-left: 51px">
          <el-input
            v-model="searchParams.policyCompany"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="出险类型" style="margin-left: 51px">
          <el-select
            v-model="searchParams.dangerTypeId"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in dictList.dangerTypeList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出险时间" style="margin-left: 51px">
          <div>
            <el-date-picker
              v-model="dateParams.dangerDate"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="出险地点" style="margin-left: 51px">
          <el-input
            v-model="searchParams.dangerDetailAddress"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="出险设备" style="margin-left: 51px">
          <el-input
            v-model="searchParams.frameNoLike"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="案件状态" style="margin-left: 51px">
          <el-select
            v-model="searchParams.statusDesc"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in statuTypes"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="案件种类" style="margin-left: 51px">
          <el-select
            v-model="searchParams.category"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in categoryTypes"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报案人姓名" style="margin-left: 51px">
          <el-input
            v-model="searchParams.reportCasePersonName"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="报案时间" style="margin-left: 51px">
          <div>
            <el-date-picker
              v-model="dateParams.reportCaseTime"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="项目名称" style="margin-left: 51px">
          <el-select
            v-model="searchParams.projectId"
            clearable
            placeholder="请选择"
            @change="changeProject"
          >
            <el-option
              v-for="item in dictList.projectList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称" style="margin-left: 51px">
          <el-select
            v-model="searchParams.customerId"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in dictList.customerList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作机手" style="margin-left: 51px">
          <el-input
            v-model="searchParams.jiShouName"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button @click="getData"> 查询</el-button
        ><el-button @click="resetData"> 重置</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table">
      <div class="table-action">
        <div>
          <el-button @click="getData" icon="el-icon-refresh"></el-button>
        </div>
        <div class="flex align-center">
          <img
            @click="isFold = !isFold"
            style="width: 30px; height: 30px; cursor: pointer"
            src="@/assets/images/RiskControl/cl.png"
          />
          <div @click="handleExportData" class="export">导出</div>
        </div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :header-cell-class-name="headerRowClassName"
        max-height="390"
        @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          <el-empty
            description="列表暂无数据"
            :image="require('@/assets/images/RiskControl/risk-empty.png')"
          ></el-empty>
        </template>
        <el-table-column type="index" width="55"> </el-table-column>
        <el-table-column prop="caseNo" label="案件编号">
          ><template slot-scope="{ row }">{{ row.caseNo || "---" }}</template>
        </el-table-column>
        <el-table-column prop="realCaseNo" label="报案号">
          ><template slot-scope="{ row }">{{
            row.realCaseNo || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="policyNo" label="保单号">
          ><template slot-scope="{ row }">{{ row.policyNo || "---" }}</template>
        </el-table-column>
        <el-table-column prop="policyCompany" label="保险公司">
          ><template slot-scope="{ row }">{{
            row.policyCompany || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="dangerType" label="出险类型">
          ><template slot-scope="{ row }">{{
            row.dangerType || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="dangerDate" label="出险时间">
          ><template slot-scope="{ row }">{{
            row.dangerDate || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="dangerDetailAddress" label="出险地点">
          ><template slot-scope="{ row }">{{
            row.dangerDetailAddress || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="frameNo" label="出险设备">
          ><template slot-scope="{ row }">{{ row.frameNo || "---" }}</template>
        </el-table-column>
        <el-table-column prop="statusDesc" label="案件状态">
          <template slot-scope="{ row }">
            <div
              :style="{
                color: row.statusColor,
                display: 'flex',
                alignItems: 'center',
              }"
            >
              <div
                :style="{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: row.statusColor,
                }"
              ></div>
              <div style="margin-left: 5px">{{ row.statusDesc }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="totalPayAmount" label="赔付金额(元)">
          ><template slot-scope="{ row }">{{
            row.totalPayAmount || "---"
          }}</template>
        </el-table-column>
        
        <el-table-column prop="reportCasePersonName" label="报案人姓名">
          ><template slot-scope="{ row }">{{
            row.reportCasePersonName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="reportCaseTime" label="报案时间">
          ><template slot-scope="{ row }">{{
            row.reportCaseTime || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="caseFollower" label="案件跟进人">
          ><template slot-scope="{ row }">{{
            row.caseFollower || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="projectName" label="所属项目">
          ><template slot-scope="{ row }">{{
            row.projectName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="userName" label="客户名称">
          ><template slot-scope="{ row }">{{ row.userName || "---" }}</template>
        </el-table-column>
        <el-table-column prop="jiShouName" label="操作机手">
          ><template slot-scope="{ row }">{{
            row.jiShouName || "---"
          }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }"
            ><el-button @click="handleDetails(row)" type="text"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="total">共计{{ searchParams.total || 0 }}条记录</div>
        <el-pagination
          background
          layout="sizes,prev,pager,next,jumper"
          :total="searchParams.total"
          :current-page.sync="searchParams.current"
          :page-size="searchParams.size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getProjectCustomer,
  RiskinsClaimsCaseExportAll,
} from "@/api/riskData.js";
import { insClaimsCasePage } from "@/api/claimData.js";
import { dictionaryBatch } from "@/api/policy";
import { userProject } from "@/api/SystemData";
import dayjs from "dayjs";
export default {
  data() {
    return {
      isFold: false,
      dialogVisible: false,
      detailData: [],
      dictList: {
        customerList: [],
        dangerTypeList: [],
        projectList: [],
      },
      tableData: [],
      chartData: [],
      exportData: [],
      // list chart
      showType: "list",
      chartOption: {},
      searchParams: {
        current: 1,
        size: 10,
        total: 0,
        caseNo: "",
        policyNo: "",
        projectId: "",
        //projectName: "",
        customerId: "",
        policyCompany: "",
        dangerDetailAddress: "",
        frameNoLike: "",
        statusDesc: "",
        reportCasePersonName: "",
        jiShouName: "",
        realCaseNo: "",
        dangerTypeId: "",
        category: '',
      },
      dateParams: {
        dangerDate: [],
        reportCaseTime: [],
      },
      categoryTypes: [
        { dictKey: 1, dictValue: "工程机械设备险" },
        { dictKey: 2, dictValue: "车险" },
        { dictKey: 3, dictValue: "非车险" },
      ],
      statuTypes: [
        { dictKey: 1, dictValue: "已中止", color: "#C94242" },
        { dictKey: 2, dictValue: "已结案", color: "#CCCCCC" },
        { dictKey: 3, dictValue: "案件重开", color: "#0081FF" },
        { dictKey: 4, dictValue: "重开结案", color: "#0081FF" },
        { dictKey: 5, dictValue: "已接案", color: "#00BC0D" },
        { dictKey: 6, dictValue: "验标中", color: "#0280FF" },
        { dictKey: 7, dictValue: "定损中", color: "#0081FF" },
        { dictKey: 8, dictValue: "核赔中", color: "#0081FF" },
        { dictKey: 9, dictValue: "理算中", color: "#0081FF" },
        { dictKey: 10, dictValue: "支付中", color: "#0081FF" },
      ],
    };
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    async init() {
      //this.searchParams.date = dayjs().format("YYYY-MM-DD");
      this.searchParams = { ...this.searchParams, ...this.$route.query };
      console.log({ ...this.searchParams, ...this.$route.query }, this.$route.query, '------------------------------------------------');
      await this.getDictionaryBatch();
      this.getData();
    },
    handleSelectionChange(data) {
      this.exportData = [];
      data.forEach((item) => {
        this.exportData.push(item.machineNo);
      });
    },
    handleExportData() {
      RiskinsClaimsCaseExportAll({
        ...this.searchParams,
      });
    },
    handleSizeChange(e) {
      this.searchParams.size = e;
      this.getData();
    },
    handleCurrentChange(e) {
      this.searchParams.current = e;
      this.getData();
    },
    getData() {
      let reportCaseTimeKeys = ["reportCaseTimeBegin", "reportCaseTimeEnd"];
      let dangerDateKeys = ["dangerDateBegin", "dangerDateEnd"];
      if (this.dateParams.dangerDate?.length) {
        this.dateParams.dangerDate.forEach((item, index) => {
          this.searchParams[dangerDateKeys[index]] = index
            ? dayjs(item).format("YYYY-MM-DD 23:59:59")
            : dayjs(item).format("YYYY-MM-DD 00:00:00");
        });
      } else {
        this.dateParams.dangerDate = [];
        dangerDateKeys.forEach((key) => {
          this.searchParams[key] = "";
        });
      }
      if (this.dateParams.reportCaseTime?.length) {
        this.dateParams.reportCaseTime.forEach((item, index) => {
          this.searchParams[reportCaseTimeKeys[index]] = index
            ? dayjs(item).format("YYYY-MM-DD 23:59:59")
            : dayjs(item).format("YYYY-MM-DD 00:00:00");
        });
      } else {
        this.dateParams.reportCaseTime = [];
        reportCaseTimeKeys.forEach((key) => {
          this.searchParams[key] = "";
        });
      }
      //this.findeProjectName();
      let apiParams = {};
      apiParams.orderType = 2;
      Object.keys(this.searchParams).forEach((key) => {
        if (this.searchParams[key]) {
          apiParams[key] = this.searchParams[key];
        }
      });
      insClaimsCasePage(apiParams).then((res) => {
        this.tableData = res.data.records.map((item) => {
          let obj = this.statuTypes.find(
            (st) => st.dictKey == item.statusValue
          );
          if (obj) {
            item.statusColor = obj.color;
          }
          return item;
        });
        this.searchParams.total = res.data.total;
      });
    },
    changeShowType(e) {},
    async getDictionaryBatch() {
      dictionaryBatch({
        codes: "dangerType",
      }).then((res) => {
        this.dictList.dangerTypeList = res.data.dangerType || [];
      });
      let proRes = await userProject({
        userId: this.$store.state.userInfo.MJUserId,
      });
      if (proRes.data.length > 0) {
        this.dictList.projectList = proRes.data.map((item) => {
          return {
            dictKey: item.projectId + '',
            dictValue: item.projectName,
          };
        });
        this.getCustomerList();
      }
    },
    findeProjectName() {
      this.searchParams.projectName =
        this.dictList.projectList.find((item) => {
          return item.dictKey == this.searchParams.projectId;
        })?.dictValue || "";
    },
    getCustomerList() {
      this.dictList.customerList = [];
      getProjectCustomer({ projectId: this.searchParams.projectId }).then(
        (res) => {
          if (res.data.records) {
            this.dictList.customerList = res.data.records.map((item) => {
              return {
                dictKey: item.customerUserId,
                dictValue: item.customerName,
              };
            });
          } else {
            this.dictList.customerList = [];
          }
        }
      );
    },
    handleDetails(item) {
      this.$router.push({
        path:
          "/routerClaimManagement/claimDetails/" + item.id + "?isDisabled=true",
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return "zdy-row";
      }
    },
    headerRowClassName() {
      return "zdy-header-row";
    },
    back() {
      this.$router.back();
    },
    resetData() {
      (this.searchParams = {
        current: 1,
        size: 10,
        total: 0,
        caseNo: "",
        policyNo: "",
        projectId: "",
        projectName: "",
        customerId: "",
        policyCompany: "",
        dangerDetailAddress: "",
        frameNoLike: "",
        statusDesc: "",
        reportCasePersonName: "",
        jiShouName: "",
        realCaseNo: "",
        dangerTypeId: "",
        ...this.$route.query,
      }),
        (this.dateParams = {
          dangerDate: [],
          reportCaseTime: [],
        }),
        this.getCustomerList();
      this.getData();
    },
    changeProject(e) {
      //console.log(e);
      this.searchParams.customerId = "";
      this.getCustomerList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  width: 300px;
}
::v-deep .el-input__inner {
  width: 100%;
}
::v-deep .el-select {
  width: 100%;
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.main-container {
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  height: 100%;
  .navigation-bar {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  .filter {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 16px 15px;
  }
  .table {
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    padding: 16px 12px;
    margin-top: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    &-action {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    .pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      .total {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }
}
.chart {
  width: 100%;
  flex: 1;
}
.export {
  background: #f5f5f5;
  border-radius: 4px 4px 4px 4px;
  padding: 8px 13px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin-left: 16px;
  cursor: pointer;
}
.point {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.back-btn {
  padding: 7px 12px;
  background: #4278c9;
  border-radius: 17px 17px 17px 17px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
</style>
<style>
.el-table .zdy-row {
  background: rgba(235, 243, 255, 0.5);
}
.zdy-header-row {
  background-color: #f2f7fd !important;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
</style>
